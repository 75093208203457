import "./App.css";
import { Passwordless, usePasswordless } from "./passwordless/react";
import { useState } from "react";
import { bufferToBase64 } from "./passwordless/util";

function App() {
  const {
    signOut,
    signInStatus,
    showAuthenticatorManager,
    toggleShowAuthenticatorManager,
    tokensParsed,
  } = usePasswordless();

  const [showStepUpAuth, setShowStepUpAuth] = useState(false);
  if (showStepUpAuth && signInStatus !== "SIGNED_IN") setShowStepUpAuth(false);

  return (
    <div className="app">
      <div>Hi there {tokensParsed?.idToken["cognito:username"]}</div>
      <button
        onClick={() => {
          signOut();
        }}
      >
        Sign out
      </button>
      <button
        onClick={() => toggleShowAuthenticatorManager()}
        disabled={showAuthenticatorManager}
      >
        Manage authenticators
      </button>
      <button
        onClick={() => {
          document.cookie = `sid=${bufferToBase64(new TextEncoder().encode(JSON.stringify(tokensParsed)))}; SameSite=strict; Secure; Path=/d3demo/;`;
          location.href = "/d3demo/index.html";
        }}>
        Play Doom 3
      </button>
    </div>
  );
}

export default App;
